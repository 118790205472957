import { adminClient } from "@api/admin/AdminClient";
import DateRangePickerComponent from "@components/admin/DateRangePickerComponent";
import InternalLink from "@components/InternalLink";
import { Box, Button, Card, CardContent, FormControlLabel, FormGroup, LinearProgress, MenuItem, Switch, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import FileDownloadIcon from "@material-ui/icons/CloudDownload";
import { AdminSettledTransactionsResponse, SettledChargeInvoicesEnumsResponse, SettledChargeInvoicesRequestEnums } from "@reshopper/admin-client";
import { formatNumber } from "@utils/formatting";
import { toDanishDateFormat, toDanishDateFormatWithOutTime } from "@utils/miscellaneous";
import { addDays, differenceInDays, sub } from "date-fns";
import _ from "lodash";
import { useEffect, useState } from "react";
import { CSVDownload } from "react-csv";


export default function FinancialListComponent() {
	const [adminTransactionResponses, setAdminTransactionResponses] = useState<AdminSettledTransactionsResponse[]>([]);
	const [loading, setLoading] = useState(false);
	const [fromDate, setFromDate] = useState<Date | null>(null);
	const [toDate, setToDate] = useState<Date | null>(null);
	const [currentDateLoading, setCurrentDateLoading] = useState<Date | null>(null);
	const [progress, setProgress] = useState(0);
	const [enums, setEnums] = useState<SettledChargeInvoicesEnumsResponse>();
	const [currentlySelectedEnum, setCurrentlySelectedEnum] = useState<SettledChargeInvoicesRequestEnums>("allWithFee");
	const [transactionsCsv, setTransactionsCsv] = useState<string>();
	const [isFileDownloading, setIsCsvDownloading] = useState<boolean>(false);
	const [shouldUseReepay, setShouldUseReepay] = useState<boolean>(false);

	useEffect(() => {
		fetchEnums();
		async function fetchEnums() {
			let enums = await adminClient().adminTransactionsSettledChargeInvoicesEnumsGet();
			setEnums(enums)
		}
	},
		[]);

	function calcSumNumbers() {
		let numberOfItems = _.sumBy(adminTransactionResponses, o => o.numberOfItems || 0);
		let total = _.sumBy(adminTransactionResponses, o => o.total != undefined ? o.total / 100 : 0);
		let totalRefund = _.sumBy(adminTransactionResponses, o => o.refundedAmountInHundreds != undefined ? o.refundedAmountInHundreds / 100 : 0);
		let shippingRevenue = _.sumBy(adminTransactionResponses, o => o.shippingRevenue != undefined ? o.shippingRevenue / 100 : 0);
		let shippingIncome = _.sumBy(adminTransactionResponses, o => o.shippingIncome != undefined ? o.shippingIncome / 100 : 0);
		let fee = _.sumBy(adminTransactionResponses, o => o.bid.payment ? o.bid.payment.sellerProtectionFee.totalFeeAmountInHundreds / 100 : 0);
		let payout = _.sumBy(adminTransactionResponses, o => o.payout != undefined ? o.payout / 100 : 0);
		
		return <>
			<TableCell>{formatNumber(numberOfItems)}</TableCell>
			<TableCell>{formatNumber(total)}</TableCell>
			<TableCell>{formatNumber(totalRefund)}</TableCell>
			<TableCell>{formatNumber(shippingRevenue)}</TableCell>
			<TableCell>{formatNumber(shippingIncome)}</TableCell> 
			<TableCell>{formatNumber(fee)}</TableCell>
			<TableCell>{formatNumber(payout)}</TableCell>
		</>;
	}

	function getLoadingStringFromDates() {
		return `Now loading ${toDanishDateFormat(currentDateLoading)}. End Date is ${toDanishDateFormat(toDate)}`
	}

	const fetchTransactions = async () => {
		if (!fromDate || !toDate) {
			return;
		}
		setLoading(true);
		setAdminTransactionResponses([]);
		try {

			let allTransactions = new Array<AdminSettledTransactionsResponse>();

			if (shouldUseReepay) {
				/*
			This subtraction of days are done to search for invoices in Reeapy server side. 
			Reepay does not provide a way to search for invoices by their settled day,
			so we need to search 10 days back, to find possible invoices that have been
			settled between the dates picked in the date picker
		*/
				const numberOfDaysInPastToSearchForReepayInvoices = 10;
				var searchFromDate = sub(fromDate, { days: numberOfDaysInPastToSearchForReepayInvoices });
				let totalDates = differenceInDays(toDate, searchFromDate);

				let dayOffset = 0;
				while (dayOffset <= totalDates) {
					setProgress((dayOffset / totalDates + 1) * 100);

					let currentDay = addDays(searchFromDate, dayOffset);
					setCurrentDateLoading(currentDay);

					let oneDayLaterThanFrom = addDays(currentDay, 1);

					let dateTransactions = await adminClient().adminTransactionsSettledTransactionsWithReepayPost({
						body: {
							searchFromDate: currentDay,
							searchToDate: oneDayLaterThanFrom,
							settledFromDate: fromDate,
							settledToDate: toDate,
							settledChargeEnum: currentlySelectedEnum
						}
					});

					if (dateTransactions.length !== 0) {
						allTransactions.push(...dateTransactions);
					}
					dayOffset++;
				}
			}

			else {
				let totalDates = differenceInDays(toDate, fromDate);
				let dayOffset = 0;
				while (dayOffset <= totalDates) {
					setProgress((dayOffset / totalDates + 1) * 100);

					let currentDay = addDays(fromDate, dayOffset);
					setCurrentDateLoading(currentDay);

					let oneDayLaterThanFrom = addDays(currentDay, 1);
					let dateTransactions = await adminClient().adminTransactionsSettledTransactionsMongoPost({
						body: {
							searchFromDate: currentDay,
							searchToDate: oneDayLaterThanFrom,
							settledChargeEnum: currentlySelectedEnum
						}
					});


					if (dateTransactions.length !== 0) {
						allTransactions.push(...dateTransactions);
					}
					dayOffset++;
				}
			}

			setAdminTransactionResponses(allTransactions);
		} finally {
			setLoading(false);
		}
	}

	const handleCsvDownloading = async () => {
		try {
			if (!fromDate || !toDate) {
				return;
			}
			setLoading(true);
			setIsCsvDownloading(true)
			setTransactionsCsv(undefined)

			let csvString = "Id;Type;TradeFinalizedUtc;SellerName;BuyerName;NumberOfItems;Total;Refund;ShippingRevenue;ShippingIncome;Fee;Payout;ReadyForPayoutAtUtc;PayedOut\r\n";
			let total = 0;
			let refundAmount = 0;
			let shippingRevenue = 0;
			let shippingIncome = 0;
			let fee = 0;
			let payout = 0;
			let dayOffset = 0;
			let numberOfItems = 0;

			if (shouldUseReepay) {
				/*
				This subtraction of days are done to search for invoices in Reeapy server side. 
				Reepay does not provide a way to search for invoices by their settled day,
				so we need to search 10 days back, to find possible invoices that have been
				settled between the dates picked in the date picker
			*/

				const numberOfDaysInPastToSearchForReepayInvoices = 10;
				var searchFromDate = sub(fromDate, { days: numberOfDaysInPastToSearchForReepayInvoices });
				let totalDates = differenceInDays(toDate, searchFromDate);

				while (dayOffset <= totalDates) {
					setProgress((dayOffset / totalDates + 1) * 100);

					let currentDay = addDays(searchFromDate, dayOffset);
					setCurrentDateLoading(currentDay);

					let oneDayLaterThanFrom = addDays(currentDay, 1);

					let TransactionsCsv = await adminClient().adminTransactionsSettledTransactionsCsvReepayPost({
						body: {
							searchFromDate: currentDay,
							searchToDate: oneDayLaterThanFrom,
							settledFromDate: fromDate,
							settledToDate: toDate,
							settledChargeEnum: currentlySelectedEnum
						}
					});
					dayOffset++;
					csvString += TransactionsCsv.fileContents
					total += TransactionsCsv.total
					refundAmount += TransactionsCsv.refund
					shippingRevenue += TransactionsCsv.shippingRevenue
					shippingIncome += TransactionsCsv.shippingIncome
					fee += TransactionsCsv.fee
					payout += TransactionsCsv.payout
					numberOfItems += TransactionsCsv.numberOfItems

				}
			}
			else {
				let totalDates = differenceInDays(toDate, fromDate);
				while (dayOffset <= totalDates) {
					setProgress((dayOffset / totalDates + 1) * 100);

					let currentDay = addDays(fromDate, dayOffset);
					setCurrentDateLoading(currentDay);

					let oneDayLaterThanFrom = addDays(currentDay, 1);

					let TransactionsCsv = await adminClient().adminTransactionsSettledTransactionsCsvMongoPost({
						body: {
							searchFromDate: currentDay,
							searchToDate: oneDayLaterThanFrom,
							settledChargeEnum: currentlySelectedEnum
						}
					});
					csvString += TransactionsCsv.fileContents
					total += TransactionsCsv.total
					refundAmount += TransactionsCsv.refund
					shippingRevenue += TransactionsCsv.shippingRevenue
					shippingIncome += TransactionsCsv.shippingIncome
					fee += TransactionsCsv.fee
					payout += TransactionsCsv.payout
					numberOfItems += TransactionsCsv.numberOfItems
					dayOffset++;
				}
			}
			console.log(total)
			csvString += "NumberOfItems;Total;RefundedAmount;Shipping Revenue;Shipping Income;Fee;Payout amount;\r\n"
			csvString += numberOfItems + ";";
			csvString += formatNumber(total) + ";"
			csvString += formatNumber(refundAmount) + ";"
			csvString += formatNumber(shippingRevenue) + ";"
			csvString += formatNumber(shippingIncome) + ";"
			csvString += formatNumber(fee) + ";"
			csvString += formatNumber(payout) + ";"
			setTransactionsCsv(csvString);
		} finally {
			setLoading(false);
			setIsCsvDownloading(false)
		}
	}



	return <Card id="TopCard">
		{transactionsCsv && <CSVDownload data={transactionsCsv} target="_self" />}
		<CardContent>
			{loading ?
				<>
					<Box alignContent="">{getLoadingStringFromDates()}</Box>
					<LinearProgress variant="determinate" value={progress} />
				</>
				:
				<>
					<Box mb={2}>
						<Box
							display="flex"
							flexDirection="reverse-row"
							alignItems="center"
							flexWrap="nowrap"
							justifyContent="flex-end"
						>
							<Box flexGrow={1} />
							<Box mb={2}>
							</Box>
							{enums?.enums &&
								<>
									<TextField
										select
										style={{ minWidth: "184px", marginRight: 16 }}
										variant={"outlined"}
										size="small"
										value={currentlySelectedEnum}
										onChange={(e: any) => setCurrentlySelectedEnum(e.target.value as SettledChargeInvoicesRequestEnums)}
									>
										{enums.enums.map((value) =>
											<MenuItem
												value={value as SettledChargeInvoicesRequestEnums}
												key={value}>
												{value.charAt(0).toUpperCase() + value.slice(1)}
											</MenuItem>)
										}c
									</TextField>
								</>
							}
							<DateRangePickerComponent
								renderTimeOption={false}
								renderDateRanges={true}
								disableFutureDates={true}
								disablePastDates={false}
								updateFromDateOnParent={setFromDate}
								submitOnLoad={false}
								updateToDateOnParent={setToDate}
								variant="outlined" />
							<Button
								variant="contained"
								style={{ marginLeft: 16 }}
								color="primary"
								disabled={isFileDownloading || loading}
								onClick={async () => await fetchTransactions()}
							> Submit </Button>
							<Button
								variant="contained"
								style={{ marginLeft: 16 }}
								color="primary"
								disabled={isFileDownloading || loading}
								onClick={async () => await handleCsvDownloading()}
							>
								<span><FileDownloadIcon /><br /> Download </span>
							</Button>
							<FormGroup>
								<FormControlLabel control=
									{<Switch
										onClick={() => setShouldUseReepay(!shouldUseReepay)}
										color="primary"
										name="shouldUseReepay"
										aria-label="Use Reepay"
										checked={shouldUseReepay}
										inputProps={{ 'aria-label': 'primary checkbox' }}
									/>}
									label="Should use Reepay"
									labelPlacement="bottom" />
							</FormGroup>
						</Box>
					</Box>
					<Box display="flex" flexDirection="row-reverse">
						{adminTransactionResponses.length > 0 && !loading && <Button href="#BottomVatText"
							variant="contained"
							style={{ marginLeft: 16 }}
							color="primary">
							Til bunden
						</Button>
						}
					</Box>
					<Table size="small" padding="none" aria-label="dense">
						<TableHead>
							<TableRow>
								<TableCell>#</TableCell>
								<TableCell>Type</TableCell>
								<TableCell>Finalized</TableCell>
								<TableCell>Seller</TableCell>
								<TableCell>Buyer</TableCell>
								<TableCell>Number of items</TableCell>
								<TableCell>Total</TableCell>
								<TableCell>Refund</TableCell>
								<TableCell>Shipping revenue</TableCell>
								<TableCell>Shipping income</TableCell>
								<TableCell>Fee</TableCell>
								<TableCell>Payout amount</TableCell>
								<TableCell>Payout ready</TableCell> 
								<TableCell>Payed out</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{adminTransactionResponses.map((transactionResponse, index) => (
								<TableRow key={transactionResponse.id}>
									<TableCell>
										{transactionResponse.shortId ?
											<InternalLink href={"/admin/transactions/details/?id=" + encodeURIComponent(transactionResponse.id)}>
												{index + 1}
											</InternalLink> :
											"No Short ID"}
									</TableCell>
									<TableCell>
										<span style={{ whiteSpace: "nowrap" }}>
											{transactionResponse.type}
										</span>
									</TableCell>
									<TableCell>
										<span style={{ whiteSpace: "nowrap" }}>
											{transactionResponse.tradeFinalizedUtc ?
												toDanishDateFormat(transactionResponse.tradeFinalizedUtc) :
												"No finalized date"}
										</span>
									</TableCell>
									<TableCell>
										<span style={{ whiteSpace: "nowrap" }}>
											{transactionResponse.sellerName}
										</span>
									</TableCell>
									<TableCell>
										<span style={{ whiteSpace: "nowrap" }}>
											{transactionResponse.buyerName} {!!transactionResponse.plusTypeBuyer ? `(${transactionResponse.plusTypeBuyer})` : ""}
										</span>
									</TableCell>
									<TableCell>
										{transactionResponse.numberOfItems ?
											formatNumber(transactionResponse.numberOfItems) :
											"No items"}
									</TableCell>
									<TableCell>
										{transactionResponse.total ?
											formatNumber(transactionResponse.total / 100) :
											"No Total"}
									</TableCell>
									<TableCell>
										{transactionResponse.refundedAmountInHundreds ?
											formatNumber(transactionResponse.refundedAmountInHundreds / 100) :
											"-"}
									</TableCell>
									<TableCell>
										{transactionResponse.shippingRevenue ?
											formatNumber(transactionResponse.shippingRevenue / 100) :
											"0"}
									</TableCell>
									<TableCell>
										{transactionResponse.shippingIncome ?
											formatNumber(transactionResponse.shippingIncome / 100) :
											"0"}
									</TableCell>
									<TableCell>
										{transactionResponse.bid.payment ? formatNumber(transactionResponse.bid.payment.sellerProtectionFee.totalFeeAmountInHundreds / 100) : "No Fee"}
									</TableCell>
									<TableCell>
										{transactionResponse.payout ?
											formatNumber(transactionResponse.payout / 100) :
											"No payout"}
									</TableCell>
									<TableCell>
										<span style={{ whiteSpace: "nowrap" }}>
											{transactionResponse.readyForPayoutAtUtc ?
												toDanishDateFormatWithOutTime(transactionResponse.readyForPayoutAtUtc) :
												"-"}
										</span>
									</TableCell>
									<TableCell>
										{transactionResponse.payoutAtUtc ?
											<CheckIcon></CheckIcon> :
											"-"}
									</TableCell>
								</TableRow>
							))}
							<TableRow key="emptyRow"
								style={{ height: 34 }}>
								<TableCell>{" "}</TableCell>
								<TableCell></TableCell>
								<TableCell></TableCell>
								<TableCell></TableCell>
								<TableCell></TableCell>
								<TableCell></TableCell>
								<TableCell></TableCell>
								<TableCell></TableCell>
								<TableCell></TableCell>
								<TableCell></TableCell>
								<TableCell></TableCell>
								<TableCell></TableCell>
							</TableRow>
						</TableBody>
						<TableHead>
							<TableRow key="totals">
								<TableCell></TableCell>
								<TableCell></TableCell>
								<TableCell></TableCell>
								<TableCell></TableCell>
								<TableCell></TableCell>
								<TableCell>Number of Items</TableCell>
								<TableCell>Total</TableCell>
								<TableCell>TotalRefund</TableCell>
								<TableCell>Shipping revenue</TableCell>
								<TableCell>Shipping income</TableCell>
								<TableCell>Fee</TableCell>
								<TableCell>Payout amount</TableCell>
								<TableCell></TableCell>
								<TableCell></TableCell>
							</TableRow>
							<TableRow key="sum">
								<TableCell>Sum</TableCell>
								<TableCell></TableCell>
								<TableCell></TableCell>
								<TableCell></TableCell>
								<TableCell></TableCell>
								{calcSumNumbers()}
								<TableCell></TableCell>
								<TableCell></TableCell>
							</TableRow>
						</TableHead>
					</Table>
				</>}
		</CardContent>
		<Box display="flex" justifyContent="space-between" alignItems="center" padding={1}>
			<div id="BottomVatText" style={{
				marginLeft: '15px',
			}}> All amounts in DKK incl .vat</div>
			{adminTransactionResponses.length > 0 && !loading &&
				<Button href="#TopCard"
					variant="contained"
					style={{ marginRight: 16 }}
					color="primary">
					Til toppen
				</Button>
			}
		</Box>
	</Card>
}

